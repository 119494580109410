/* ------- the AI Speak to Us ---------- */
.ai-support-all {
    display: flex;
    justify-content: center;
    align-items: center;
  
    padding-top: 10px;
    padding-left: 50px;
    padding-right: 50px;
  }
  
  .ai-main-speaktous {
    padding: 50px 0;
  }
  
  .divider-stu {
    font-size: 25px;
    font-weight: 400;
    margin-bottom: 65px;
    position: relative;
  }
  
  .divider-stu::before,
  .divider-stu::after {
    content: "";
    position: absolute;
    top: 50%;
    width: calc(50% - 80px);
    height: 3px;
    background-color: #e30d3c;
  }
  
  .divider-stu::before {
    left: 0;
  }
  
  .divider-stu::after {
    right: 0;
  }
  
  .left-div-speaktous {
    width: 50%;
    float: right;
    padding: 160px 61px;
  }
  
  .left-head-para-speaktous {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 8rem;
    color: #333;
  }
  
  .left-head-text-speaktous {
    font-size: 52px;
    margin-top: 33px;
    margin-bottom: 26px;
    font-weight: 600;
    color: #e30d3c;
  }
  
  .left-head-subtext-speaktous {
    margin-bottom: 42px;
    word-wrap: break-word;
    letter-spacing: 1px;
  }
  
  .ai-button {
    background-color: #e30d3c;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
  }
  
  .right-div-speaktous {
    width: 50%;
    float: left;
    padding: 0 20px;
  }
  
  .form-div-ai {
    background-color: #ffffff;
  
    border-radius: 5px;
    padding-left: 96px;
    padding-right: 96px;
    padding-bottom: 96px;
    padding-top: 40px;
  }
  
  .form-speaktous-text {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 30px;
    color: #ababab;
    padding-left: 92px;
  }
  .form-speaktous-head {
    font-size: 48px;
    margin-top: 0;
    margin-bottom: 20px;
    padding-left: 92px;
  }
  
  .form-group-stu {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    font-weight: bold;
  }
  
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea {
    width: 97%;
    padding: 10px;
    border: 1px solid #e30d3c;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  input[type="text"],
  input[type="email"],
  input[type="tel"] {
    height: 40px;
  }
  .horizontal-input{
    padding-left: 2px !important;
  }
  
  textarea {
    height: 120px;
  }
  
  .ai-button-stu {
    background-color: #e30d3c;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 5px;
    font-weight: 700;
  }
  
  .ai-button-stu:hover,
  .ai-button-stu:focus {
    background-color: #218838;
  }
  .form-horizontal {
    display: flex;
    justify-content: space-between;
  }
  .custom-shape-divider-bottom-1713423034 {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
  }
  
  .custom-shape-divider-bottom-1713423034 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 118px;
  }
  
  .custom-shape-divider-bottom-1713423034 .shape-fill {
    fill: #e30d3c;
  }
  @media only screen and (max-width: 767px){
    .ai-container-speaktous{
    
        display: flex;
        flex-direction: column;
        align-items: center;
    
    }
    .left-div-speaktous {
      width: 100%;
      float: right;
      padding: 1px 0px;
  }
  .left-head-text-speaktous {
    font-size: 26px;
    margin-top: 33px;
    margin-bottom: 26px;
    font-weight: 600;
    color: #e30d3c;
}
.divider-stu {
  font-size: 25px;
  font-weight: 400;
  margin-bottom: 65px;
  position: relative;
  left: 0px;
}
.form-horizontal {
  display: flex;
  justify-content: space-between;
  gap: 6px;
}
.right-div-speaktous {
  width: 109%;
  /* float: right; */
  padding-top: 12px;
}
.ai-support-all {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-left: 25px;
  padding-right: 25px;
}
.form-speaktous-text {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 30px;
  color: #ababab;
  padding-left: 0px;
}
.form-speaktous-head {
  font-size: 37px;
  margin-top: 0;
  margin-bottom: 20px;
  padding-left: 0px;
}
.form-div-ai {
  background-color: #ffffff;
  border-radius: 5px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 20px;
  padding-top: 9px;
}
.left-head-para-speaktous {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 0rem;
  color: #333;
}
label {
  display: block;
  font-weight: 400;
}
  }


  @media only screen and (min-width: 768px) and (max-width: 1023px){
.ai-container-speaktous{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.left-div-speaktous {
  width: 100%;
  float: right;
  padding: 3px 23px;
}
.right-div-speaktous {
  width: 100%;
  float: left;
  padding-top: 11px;
}
.form-speaktous-text {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 30px;
  color: #ababab;
  padding-left: 0px;
}
.form-speaktous-head {
  font-size: 48px;
  margin-top: 0;
  margin-bottom: 20px;
  padding-left: 0px;
}
.form-div-ai {
  background-color: #ffffff;
  border-radius: 5px;
  padding-left: 0px;
  padding-right: 77px;
  padding-bottom: 96px;
  padding-top: 40px;
}
  }