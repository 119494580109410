.container-2 {
  padding-left: 2rem;
  padding-right: 2rem;
}
.footer-head {
  color: white;
}
@keyframes slideIn {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

footer {
  background-color: #000;
  color: white;
  padding: 50px 0;
  animation: slideIn 0.5s ease-in-out forwards;
}

footer .grid {
  grid-template-columns: repeat(1, 2fr);
  display: grid;
  gap: 3rem;
}
.f-u {
  display: flex;
  justify-content: space-between;
}
footer h2 {
  margin-bottom: 20px;
  letter-spacing: 2px;
  font-size: 1.5rem;
  font-weight: 600;
}
footer p {
  font-size: 13px;
  opacity: 0.7;
  font-weight: 400;
  letter-spacing: 1px;
}
footer .icon i {
  color: black;
}

footer ul li a {
  opacity: 0.7;
  color: white;
  font-weight: 600;
  font-size: 13px;
  text-decoration: none;
  transition: color 0.3s ease, font-size 0.3s ease;
}

footer ul li a:hover {
  color: #e30d3c;
  font-size: 15px;
  font-weight: 700;
}
.div-span-post {
  display: flex;
  justify-content: space-between;
}

footer ul li {
  margin-bottom: 10px;
}
footer .post li {
  margin-bottom: 4px;
  border-bottom: 1px solid rgb(63, 62, 62);
}
footer .post li:nth-last-child(1) {
  border-bottom: none;
}
footer .post label,
footer .post span {
  opacity: 0.5;
  margin-right: 10px;
  font-size: 13px;
  margin-bottom: 20px;
}
footer input {
  background: azure;
  border: 1px solid rgb(63, 62, 62);
  border-radius: 10px;
  margin: 10px 0;
  width: -webkit-fill-available;
}
footer .primary-btn {
  padding: 9px 20px;
  border-radius: 10px;
  text-align: center;
  color: #fff;
  font-weight: 700;
  width: 100%;
  background-color: #e30d3c;
}
.legal {
  text-align: center;
  padding: 20px;
  background-color: #000;
  border-top: 1px solid rgb(63, 62, 62);
  color: white;
}
.flex_space2 {
  gap: 0.8rem;
  display: flex;
}
.legal p {
  opacity: 0.5;
}

@keyframes colorChange {
  0% {
    color: #ffffff;
    transform: scale(1);
  }
  50% {
    color: #e30d3c;
    transform: scale(1.2);
  }
  100% {
    color: #ffffff;
    transform: scale(1);
  }
}

.icon-socio {
  width: 2rem;
  height: auto;
  animation: colorChange 5s infinite;
}

@media screen and (max-width: 768px) {
  footer .grid {
    display: flex;
    flex-direction: column;
  }
  .flex_space {
    gap: 0.8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .flex_space2 {
    gap: 0.8rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .slide-form input {
    margin-top: 20px;
    padding: 6px;
    width: -webkit-fill-available;
  }
  footer h2 {
    margin-bottom: 20px;
    letter-spacing: 2px;
    font-weight: 600;
    justify-content: center;
    display: flex;
  }
  .f-u{
    display: flex;
    justify-content: center;
  }
  ul {
    margin-top: 0;
    margin-bottom: 1rem;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 2rem;
  }
  footer p {
    font-size: 13px;
    opacity: 0.7;
    font-weight: 400;
    letter-spacing: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: column;
    text-align: justify;
}
}

@media (min-width: 425px) and (max-width: 768px) {
  .down-subs {
    display: flex;
    gap: 16px;
    flex-direction: row;
  }
  footer p {
    font-size: 13px;
    opacity: 0.7;
    font-weight: 400;
    letter-spacing: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    text-align: justify;
    flex-direction: column;
  }
  .f-u {
    display: flex;
    justify-content: center;
    gap: 2rem !important;
}
}
