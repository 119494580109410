.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  color: #e30d3c;
  padding: 25px 30px;
  font-size: 16px;
  font-weight: 700;
}

@keyframes underline {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.navbar-ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.navbar-ul li {
  display: inline-block;
  margin-right: 20px;
}

.navbar-ul li a {
  position: relative;
  text-decoration: none;
  color: black;
}

.navbar-ul li a::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #e30d3c;
  transition: width 0.3s ease;
}

.navbar-ul li a:hover::after {
  width: 100%;
}

.dropdown-one .dropdown-text-main {
  color: #e30d3c;
}

.dropdown-content,
.dropdown-content-2 {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  color: #e30d3c;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 50rem;
  right: 1rem;
}

.dropdown-content-2 {
  min-width: 165px;
  width: 12rem;
  right: 0rem;
}

.dropdown-content a,
.dropdown-content-2 a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 400;
}

.dropdown-content a:hover,
.dropdown-content-2 a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content,
.dropdown:hover .dropdown-content-2 {
  display: block;
}

.dropdown-text {
  font-weight: bold;
  color: #e30d3c;
}

.nav-icon {
  margin-right: 0.5rem;
  color: #e30d3c;
}
.nav-icon2{
  width: 1rem;
  height: auto;
  color: #e30d3c;
  fill: #e30d3c;
  margin-right: 4px;
}

.navbar-links {
  display: flex;
}

.navbar-logo img {
  height: 50px;
  width: 140px;
}

.logo-img {
  background-color: #f9f9f9;
  border-radius: 20px;
  filter: contrast(3.5);
}
.dropdown-all{
  display: flex;
}
@media only screen and (max-width: 767px) {
  .navbar-links {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    padding: 10px 0;
   
  }
 

  .navbar-logo img {
    height: 42px;
    width: 127px;
}
.dropdown-content{
  width: 14rem;
}
.dropdown-all {
  font-size: 12px;
  display: flex;
  flex-direction: column;
}

  .navbar-links.active {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
}

  .navbar-toggle {
    display: block;
    cursor: pointer;
    padding: 10px;
  }

  .toggle-bar {
    width: 25px;
    height: 3px;
    background-color: #e30d3c;
    margin: 5px 0;
  }

  .navbar-ul {
    display: flex;
    flex-direction: column;
    
  }

  .navbar-ul li {
    margin: 10px 0;
  }
}


@media (min-width: 425px) and (max-width: 768px){
  .navbar-ul {
    list-style-type: none;
    margin:0;
    padding: 5px;
  }
  ul {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 1rem !important;
}
.dropdown-content{
  position: absolute;
  right: -297px;
  width: 45rem;
  top: 62px;
}
}
